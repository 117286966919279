import logo from './logo.svg';
import './App.css';
import { useState, useRef } from 'react';
import { Button, Text, TextInput, Group, Loader, NativeSelect, NumberInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import html2canvas from 'html2canvas';
import Barcode from 'react-barcode';

function App() {
	const [loading, setLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [detailsLoaded, setDetailsLoaded] = useState(false);
	const [checkedIn, setCheckedIn] = useState(false);
	const [uniqueCodeErr, setUniqueCodeErr] = useState('');
	const [entryTodayErr, setEntryTodayErr] = useState(false);
	const [entryDoneErr, setEntryDoneErr] = useState(false);
	const barcodeRef = useRef();
	const dateChecker = 'entry' + new Date().getDate();

	const userForm = useForm({
		initialValues: {
			name: '',
			id: '',
			kid1: 'N/A',
			kid2: 'N/A',
			allowed19: '',
			allowed20: '',
			checkedIn: '',
			entry19: '',
			entry20: '',
			giftCollected: '',
			totalMembers: 0,
			unique_code: '',
			entryTime: '',
		},
	});

	const handleDownloadBarcode = () => {
		html2canvas(barcodeRef.current).then((canvas) => {
			const link = document.createElement('a');
			link.href = canvas.toDataURL('image/png'); // Convert canvas to data URL
			link.download = 'barcode.png'; // Set the file name
			link.click(); // Trigger download
		});
	};

	const handleSubmitCode = () => {
		setLoading(true);
		setLoadingMessage('Fetching details...');

		fetch(
			'https://fu721ee2ff.execute-api.eu-north-1.amazonaws.com/dev/getUserDetails/' +
				userForm.values['unique_code']
		)
			.then((response) => {
				if (!response.ok) {
				}
				return response.json();
			})
			.then((data) => {
				console.log(data);
				data.totalMembers = data.totalMembers === 0 ? data.totalMembers + 1 : data.totalMembers;
				userForm.setValues(data);
				setDetailsLoaded(true);
				setLoading(false);
				setLoadingMessage('');
				setCheckedIn(data.checkedIn);

				if (
					data['allowed19'] === 'Yes' &&
					data['allowed20'] === 'Yes' &&
					new Date().getDate() > 25 &&
					data[dateChecker]
				)
					setEntryTodayErr(true);

				if (
					(data['allowed19'] !== data['allowed20'] && data['entry19'] !== data['entry20']) ||
					(data['allowed19'] === 'No' &&
						data['allowed20'] === 'No' &&
						data['entry19'] !== data['entry20'])
				)
					setEntryDoneErr(true);
			})
			.catch((error) => {
				console.log(error);
				setUniqueCodeErr('Incorrect code entered');
				// window.alert('There was some error while scanning user code. Please try again.');
				setLoading(false);
				setLoadingMessage('');
			});
	};

	const handleUpdateDetails = () => {
		setLoading(true);
		setLoadingMessage('Submitting details...');
		userForm.values['checkedIn'] = true;
		fetch('https://fu721ee2ff.execute-api.eu-north-1.amazonaws.com/dev/updateDetails', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(userForm.values),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				console.log(data);
				setCheckedIn(true);
				setLoading(false);
				setLoadingMessage('');
			})
			.catch((error) => {
				console.log(error);
				window.alert('There was some error while user entry. Please try again.');
				setLoading(false);
				setLoadingMessage('');
			});
	};

	return (
		<div className='App container'>
			<div className='side'>
				{entryDoneErr || entryTodayErr ? (
					<div className='middle'>
						<div>
							<img src='./jamwithfam1.jpeg' className='App-logo' alt='logo' />
							<h3>
								<Text fw={700} c='white' className='text-padding'>
									You have already entered the event{entryTodayErr ? ' today' : ''}!<br></br>
									Please show this bar code for collecting your gift, if not collected already!
									<div ref={barcodeRef} style={{ margin: '20px auto' }}>
										<Barcode value={userForm.values['unique_code']} displayValue={false} />
									</div>
								</Text>
							</h3>
						</div>
						<Group className='button-label'>
							<Button
								size='md'
								fullWidth
								uppercase
								onClick={() => {
									setDetailsLoaded(false);
									userForm.values['unique_code'] = '';
									setUniqueCodeErr('');
									setEntryDoneErr(false);
									setEntryTodayErr(false);
								}}
							>
								Back
							</Button>
						</Group>
					</div>
				) : loading ? (
					<div className='middle-loading'>
						<Text ta='center' c='white'>
							<h2>{loadingMessage}</h2>
							<Loader size='xl' variant='bars' />
						</Text>
					</div>
				) : detailsLoaded && checkedIn ? (
					<div className='middle'>
						<div>
							<img src='./jamwithfam1.jpeg' className='App-logo' alt='logo' />
							<h3>
								<Text fw={700} c='white' className='text-padding'>
									Please show this bar code on the entry
								</Text>
							</h3>
							<div ref={barcodeRef} style={{ margin: '20px auto' }}>
								<Barcode value={userForm.values['unique_code']} displayValue={false} />
							</div>
						</div>
						<Group className='button-label'>
							<Button size='md' fullWidth uppercase onClick={handleDownloadBarcode}>
								Download Bar Code
							</Button>
							{/* <Button size='md' fullWidth uppercase onClick={handleResubmitData}>
								Update Details
							</Button> */}
						</Group>
					</div>
				) : detailsLoaded && !checkedIn ? (
					<div className='middle'>
						<div>
							<img src='./jamwithfam1.jpeg' className='App-logo-form' alt='logo' />
							<h3>
								<Text fw={700} c='white'>
									Please confirm following details
								</Text>
							</h3>
							<div
								style={{
									width: '100%',
									paddingTop: '0px',
									paddingBottom: '10px',
									paddingRight: '30px',
									paddingLeft: '30px',
									boxSizing: 'border-box',
								}}
							>
								<h4 style={{ color: '#fff', textAlign: 'left', marginBottom: '5px' }}>Name</h4>
								<input
									value={userForm.values['name']}
									disabled
									placeholder='This input is disabled'
									style={{
										width: '100%',
										padding: '10px',
										fontSize: '16px',
										backgroundColor: '#fff',
										color: '#000',
										border: '1px solid #ccc',
										borderRadius: '10px',
										boxSizing: 'border-box',
									}}
								/>
								<h4 style={{ color: '#fff', textAlign: 'left', marginBottom: '5px' }}>
									Total Members
								</h4>
								<input
									value={userForm.values['totalMembers']}
									disabled
									placeholder='This input is disabled'
									style={{
										width: '100%',
										padding: '10px',
										fontSize: '16px',
										backgroundColor: '#fff',
										color: '#000',
										border: '1px solid #ccc',
										borderRadius: '10px',
										boxSizing: 'border-box',
									}}
								/>
								<h4 style={{ color: '#fff', textAlign: 'left', marginBottom: '5px' }}>
									Age of Kid 1
								</h4>
								<input
									value={userForm.values['kid1']}
									disabled
									placeholder='This input is disabled'
									style={{
										width: '100%',
										padding: '10px',
										fontSize: '16px',
										backgroundColor: '#fff',
										color: '#000',
										border: '1px solid #ccc',
										borderRadius: '10px',
										boxSizing: 'border-box',
									}}
								/>
								<h4 style={{ color: '#fff', textAlign: 'left', marginBottom: '5px' }}>
									Age of Kid 2
								</h4>
								<input
									value={userForm.values['kid2']}
									disabled
									placeholder='This input is disabled'
									style={{
										width: '100%',
										padding: '10px',
										fontSize: '16px',
										backgroundColor: '#fff',
										color: '#000',
										border: '1px solid #ccc',
										borderRadius: '10px',
										boxSizing: 'border-box',
									}}
								/>
							</div>
						</div>
						<Group className='button-label'>
							<Button size='md' fullWidth uppercase onClick={handleUpdateDetails}>
								Confirm
							</Button>
							<Button
								size='md'
								fullWidth
								uppercase
								onClick={() => {
									setDetailsLoaded(false);
									userForm.values['unique_code'] = '';
									setUniqueCodeErr('');
								}}
							>
								Cancel
							</Button>
						</Group>
					</div>
				) : (
					<div className='middle'>
						<div>
							<img src='./jamwithfam1.jpeg' className='App-logo' alt='logo' />
						</div>
						<div>
							<h3 style={{ marginBottom: '0px' }}>
								<Text fw={700} c='white'>
									Please enter your 6 digit special code
								</Text>
							</h3>
							<h4 style={{ marginTop: '0px' }}>
								<Text fw={400} c='white' className='text-padding'>
									(Shared with you on email)
								</Text>
							</h4>
							<TextInput
								className='input-label'
								size='lg'
								variant='filled'
								radius='lg'
								{...userForm.getInputProps('unique_code')}
								// onChange={() => setUniqueCodeErr('')}
							/>
							<Text
								fw={700}
								c='red'
								className='text-padding'
								style={{ textAlign: 'left', paddingLeft: '30px' }}
							>
								{uniqueCodeErr}
							</Text>
						</div>
						<Group className='button-label'>
							<Button size='md' fullWidth uppercase onClick={handleSubmitCode}>
								Submit
							</Button>
						</Group>
					</div>
				)}
			</div>
		</div>
	);
}

export default App;
